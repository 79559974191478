<template >
  <div>
    <div class="app-modal__box" :class="mode ? '' : 'performance__night'">
      <div class="app-modal__in">
          <div
              class="app-modal__header d-flex f-between p-5 m-title-modal"
          >
              <p class="large--title m-0" :class="mode ? 'text__day2' : 'text__night2'">
                  {{
                      $t("message.edit_m", {
                          m: $t("message.vacation_graphic"),
                      })
                  }}
              </p>
              <div>
                  <crm-store-update-close
                      :permission="$options.name"
                      :button_type="'store'"
                      :loading="loadingButton"
                      @c-submit="submit(true)"
                      @c-close="closeModal(true)"
                  ></crm-store-update-close>
              </div>
          </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4 type-expensies" :class="mode ? 'table__myday' : 'table__mynight'">
          <el-form
          ref="form"
          status-icon
          :model="form"
          :rules="rules"
          >
          <el-row :gutter="20">
              <el-col :span="24" >
                      <el-form-item :class="mode ? 'input__day' : 'input__night'"
                        :label="$t('message.staff')"  class="label_mini">
                        <select-staff
                            :size="'medium'"
                            :id="form.staff_id"
                            :work_type="true"
                            v-model="form.staff_id"
                            @change="reportConsole(form.staff_id)"
                          ></select-staff>
                        </el-form-item>
                        <!-- {{ staff }} -->
                      </el-col>
                      <!-- end col -->
              
              <el-col :span="24" v-if="staff">
                <div class="mb-4">
                  <ul class="staff_info">
                    <li class="staff_info_item">
                      <b>{{$t('message.staff')}}: </b>
                      <span>{{staff.name}} {{staff.last_name}}</span>
                    </li>
                    <li class="staff_info_item">
                      <b>{{$t('message.manager')}}: </b>
                      <span v-if="staff.manager">{{staff.manager.name}} {{staff.manager.last_name}}</span>
                    </li>
                    <li class="staff_info_item">
                      <b>{{$t('message.branch')}}: </b>
                      <span>{{staff.branch?staff.branch.name:''}}</span>
                    </li>
                    <li class="staff_info_item">
                      <b>{{$t('message.department')}}: </b>
                      <span>{{staff.department.name}}</span>
                    </li>
                    <li class="staff_info_item">
                      <b>{{$t('message.position')}}: </b>
                      <span>{{staff.position.name}}</span>
                    </li>
                    <li class="staff_info_item">
                      <b>{{$t('message.hiring_date')}}: </b>
                      <span>{{staff.hiring_date}}</span>
                    </li>
                    <li class="staff_info_item">
                      <b>{{$t('message.official_work_date')}}: </b>
                      <span>{{staff.official_work_date}}</span>
                    </li>
                  </ul>
                </div>
              </el-col>
              <el-col :span="12">
                <el-form-item :class="mode ? 'input__day' : 'input__night'" prop="payment_month">
                  <span class="input--label d-block mb-0 line-h-24 title_data" :class="mode ? 'text__day2' : 'text__night2'">
                    <span class="start_top">*</span>
                    {{ $t("Период Год") }}
                  </span>
                  <el-date-picker
                    v-model="form.period_year"
                    type="monthrange"
                    align="right"
                    unlink-panels
                    range-separator="-"
                    :start-placeholder="$t('message.monthly')+' '+ $t('message.from')"
                    :end-placeholder="$t('message.monthly')+' '+ $t('message.before')"
                    :format="'MMMM yyyy'"
                    :value-format="'yyyy-MM-dd'"
                    style="width:100%">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :class="mode ? 'input__day' : 'input__night'" prop="payment_month">
                  <span class="input--label d-block mb-0 line-h-24 title_data" :class="mode ? 'text__day2' : 'text__night2'">
                    <span class="start_top">*</span>
                    {{ $t("Период День") }}
                  </span>
                  <el-date-picker
                    v-model="form.period_date"
                    type="daterange"
                    align="right"
                    unlink-panels
                    range-separator="-"
                    :start-placeholder="$t('message.from_data')"
                    :end-placeholder="$t('message.to_data')"
                    :format="'dd.MM.yyyy'"
                    :value-format="'yyyy-MM-dd'"
                    style="width:100%">
                  </el-date-picker>
                  <!-- <el-date-picker
                    class="w-100"
                    size="medium"
                    v-model="form.month"
                    type="month"
                    :format="'MMMM yyyy'"
                    :value-format="'yyyy-MM'"
                    :placeholder="$t('message.monthly')"
                  >
                  </el-date-picker> -->
                </el-form-item>
              </el-col>
            </el-row>
            </el-form>
        </div>
    </div>
      <!-- end app-modal__body -->
  </div>
</div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import selectStaff from "@/components/filters/inventory/select-staff";

/**
 * Drawer chaqirish kerak drawerni yopish uchun
 */
import form from "@/utils/mixins/form";
import show from "@/utils/mixins/show";
export default {
    mixins: [form, drawer, show],
    name: "vacationGraphicUpdate",
    data() {
        return {
          staff:this.selecedStaff
        };
    },
    watch: {
    "form.staff_id": {
      handler: function (e) {
        this.reportConsole(e)
      },
      immediate: true,
      deep: true,
    },
  },
    components:{
      selectStaff
    },
    created() {},
    computed: {
        ...mapGetters({
            rules: "vacationGraphic/rules",
            model: "vacationGraphic/model",
            columns: "vacationGraphic/columns",
            mode:"MODE"
        }),
    },
    methods: {
        ...mapActions({
            update: "vacationGraphic/update",
            getStaffInfo: "vacationGraphic/getStaffInfo",
            show: "vacationGraphic/show",
        }),
        submit(close = true) {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.loadingButton = true;
                    this.update(this.form)
                        .then((res) => {
                            this.loadingButton = false;
                            this.$alert(res);
                            this.parent().listChanged();
                            if (close) this.close();
                        })
                        .catch((err) => {
                            this.loadingButton = false;
                            this.$alert(err);
                        });
                }
            });
        },
        reportConsole(id){
          if (id) {
            this.getStaffInfo(id).then((res)=>{
              this.staff = res.data.result.data.staff;
            });            
          }
        },
        closeModal(close=true){
          this.staff=null;
          if (close) this.close();
        }
    },
};
</script>
